import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Base from '../components/Base/base'

class Blank extends React.Component {
  render() {
    const {
      subtitle,
      title,
    } = this.props.data.site.siteMetadata

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subtitle}/>
        </Helmet>
        <Base/>
        <div style={{ height: '100vh' }}/>
      </>
    )
  }
}

export default Blank

export const pageQuery = graphql`
  query BlankQuery {
    ...siteQuery
  }
`
